import * as React from "react"
import Layout from "../layout"

import '../styles/documents.scss';

const TerminosyCondiciones = () => {
    const business = "Consorcio AAA Elite S de RL de CV";
    const name = "MI MEJOR HIPOTECA";
    const email = "jlgomez@creditaria.com";
    const web = "www.mimejorhipoteca.com";
    const addresses = [
        "Av. Vicente Guerrero # 259 Col. Independencia, entre Av. Cortez y Av. Águilas, Ensenada Baja California, México."
    ];
    const phone = "1301654";

    return (
        <Layout>
            <main className="wrapper">
                <h1>Términos y condiciones</h1>
                <h2>INTRODUCCIÓN AL SERVICIO Y ACEPTACIÓN DE LAS CONDICIONES DE USO</h2>
                
                <h3>Introducción</h3>
                <p>{business}. (“{name}”, nosotros), una compañía debidamente constituida e incorporada a las leyes de los Estados Unidos Mexicanos, ofrece el servicio de asesoría hipotecaria integral (asesoría de especialistas en los productos, procesos, políticas y requisitos de las opciones financieras más importantes del mercado), el cual brinda una serie de programas (simuladores) y otros contenidos audiovisuales de manera conjunta el (“Contenido”) a través de nuestras páginas que integran el portal de {web} (“el sitio”) nuestra herramienta para ver el contenido y ciertas aplicaciones que le permiten acceder al mismo (de manera conjunta, el Servicio” o los “Servicios” ). Estos términos y condiciones detallados, que incluyen la política de Privacidad que se encuentran en {web}</p>
                
                <h3>Aceptación de las Condiciones de Uso</h3>
                <p>Para acceder y disfrutar de los Servicios, debe aceptar y en todo momento seguir las disposiciones que se establecen en los términos. Al usar los Servicios (incluyendo al acceso del contenido), usted acepta cumplir con las condiciones de Uso, por lo que pedimos dedique un tiempo a revisar cuidadosamente y si no está de acuerdo con las mismas, deberá abstenerse de acceder a utilizar el sitio.</p>
                
                <h3>El Servicio</h3>
                <p>Ofrecemos los servicios de asesoría hipotecaria integral (asesoría de especialistas en los productos, procesos, políticas y requisitos de las opciones financieras más importantes del mercado). Las referencias a los “Servicios” o el “Servicio” a lo largo de estas condiciones.</p>
                
                
                <h2>MODIFICACIÓN DE LAS CONDICIONES DE USO POR “{name}”</h2>
                <p>“{name}” puede modificar las condiciones de Uso (incluyendo la política de privacidad) en cualquier momento, a su discreción, las cuales surtirían efectos en el momento en que se publiquen las Condiciones de Uso modificadas en nuestro sitio. La versión más actual de las Condiciones de Uso prevalecerá sobre tosas las versiones previstas.</p>

                <h2>ACCESO Y USO DE LOS SERVICIOS</h2>
                <h3>El contenido</h3>
                <p>“{name}” no, promueve, aprueba o permite se copien, programas u otros contenidos entregados digitalmente, ni otras actividades delictivas. Usted no puede, ya sea directamente o con el uso de dispositivos, software, sitios de internet, servicios basados en web u otros medios. (a) retirar, alterar, eludir, evitar, o interferir avisos, de derechos de autor, marcas u otro tipo de aviso de propiedad insertados en el Contenido, o mecanismos de administración de derechos digitales, dispositivos u otras protecciones de contenido o medidas de control de acceso del contenido, ni (b) copiar, descargar (excepto a través de la memoria de almacenamiento personal de la página necesaria para uso personal, o como expresamente se permite en las Condiciones de uso ) modificar, distribuir , transmitir, mostrar, efectuar, reproducir, exhibir , duplicar, publicar, licenciar. Crear obras derivadas u ofrecer para la venta el contenido u otra información contenida u obtenida de o a través del servicio, sin nuestro consentimiento expreso por escrito. No puede incorporar el contenido, ni hacer streming o retransmitirlo en aplicaciones de hardware o de software, ni ponerlo a disposición a través de enmarcados o vínculos en línea, a menos de que “{name}” expresamente lo permita por escrito. Además, no puede crear, recrear, distribuir o publicar un índice de la parte sustancial del contenido, a menos de que “{name}” expresamente lo autorice; tampoco puede crear un negocio utilizando el contenido, ya sea con fines de lucro o no. el contenido al que aplican estas restricciones incluye, entre otros, textos, gráficos, configuraciones, interfaces, logotipos, fotografías, materiales de audio y video y fonogramas. Así mismo, se le prohíbe estrictamente crear obras derivadas o materiales que provengan o se basen en cualquier firma en el contenido, incluyendo montajes, fondos de escritorio, protector de pantalla, tarjetas de saludos.</p>

                <h2>COMO FUNCIONA EL SERVICIO</h2>
                <h3>Descripción General</h3>
                <p>“{name}” es un servicio que ofrece al público acceso al portal por internet para ofrecer asesoría hipotecaria. Nos reservamos el derecho, a nuestra absoluta y exclusiva discreción, de hacer cambios en cualquier momento y sin notificación, sobre la forma en que manejamos el servicio, por lo que las descripciones de cómo funciona el servicio no debe considerarse como una declaración u obligación respecto a cómo funcionara siempre.</p>

                <h2>PROPIEDAD Y DERECHOS DE PROPIEDAD INTELECTUAL</h2>
                <p>El servicio , incluyendo todo el contenido, software, interfaces de usuario , textos gráficos , logotipos, diseños, fotografías, iconos de botones, imágenes, videoclips, y audio, descargas digitales, compilaciones de todos y software que se incluyan en “sitio-web” y/o que se entregan al usuario como parte del servicio, son bienes de “{name}” o de sus licenciantes o proveedores de contenido, y están protegidos por leyes de las Entidades Federativas e Internacionales , de derechos de autor , marcas, secretos industriales, o de propiedad intelectual de otro tipo . Toda la propiedad intelectual de “{name}” que sea una marca, logotipo o marca de servicio también es una marca registrada o no registrada de “{name}” o de otros. Se prohíbe estrictamente el uso de la PI de “{name}” excepto de conformidad con estas condiciones de usos, sin el permiso por escrito de los dueños de dicha Propiedad Intelectual. También se le informa que “{name}” ejercerá con firmeza sus derechos de propiedad intelectual en la medida más amplia permitida por la ley.</p>

                <h2>RESPONSABILIDADES QUE LE CORRESPONDEN Y RESTRICCIONES EN EL USO</h2>
                <p>Las siguientes Normas de conducta se aplican al sitio y a los Servicios y usted se obliga a no utilizar el sitio y Servicios de manera que:</p>

                <ol>
                    <li>Violen los derechos de otros, incluyendo derechos de patentes, marcas secretos industriales, derechos de autor, privacidad, publicidad u otros, derechos de propiedad intelectual.</li>
                    <li>Usen, transfieran, distribuyan o dispongan de la información que contiene el Servicio en cualquier forma que podría competir con el negocio de “{name}” o cualquiera de sus filiales.</li>
                    <li>Resulten en el envío de correo electrónico no solicitado o spam, o en que se recopile información acerca de usuarios con el propósito de enviarles correo electrónico no solicitado o spam; introduzcan virus o códigos, archivos o programas de cómputo de otro tipo que interrumpan, destruyan o limiten la funcionalidad de software o hardware de cualquier equipo de telecomunicación.</li>
                    <li>Dañen, inhabiliten, sobrecarguen, perjudiquen u obtengan acceso no autorizado a los Servicios, incluyendo Servidores de “{name}”, redes de cómputo o cuentas de usuarios.</li>
                    <li>Retiren, modifiquen, inhabiliten, bloqueen, oculten o de otra forma afecten la publicidad relacionada con los Servicios (incluyendo el Contenido).</li>
                    <li>Utilicen los Servicios para publicitar o promover servicios que “{name}” no haya aprobado por escrito previamente.</li>
                    <li>Recaben información personal identificable violando la Política de Privacidad de “{name}”.</li>
                    <li>Alienten conductas que constituirían un delito o den lugar a responsabilidad civil.</li>
                    <li>Violen estas Condiciones de Uso, o lineamientos o políticas publicados por “{name}”.</li>
                    <li>Interfieran con el uso de otro usuario o su disfrute del Servicio.</li>
                    <li>Intenten realizar cualquiera de las acciones anteriores.</li>
                </ol>

                <p>No podemos asegurar –y no lo aseguramos– que otros usuarios cumplen o cumplirán con estas normas de conducta u otras disposiciones de las Condiciones de Uso y, en lo que compete a usted y a nosotros, en este acto usted asume todos los riesgos de daños o perjuicios que resulten de dicha falta de cumplimiento.</p>

                <h2>PRIVACIDAD Y PREFERENCIAS DE COMUNICACIONES</h2>
                <p>Para obtener información acerca de uso de su información personal, o de la Política de Privacidad. La Política de Privacidad se incorpora en estas Condiciones de Uso y es parte de los mismos; por lo tanto, al convenir en las Condiciones de Uso, también acuerda que el uso de los Servicios se regirá por la Política de Privacidad vigente en la fecha de uso. La información personal proporcionada a través del Servicio está sujeta a nuestra Política de Privacidad.</p>

                <h2>EXTENSIÓN DE GARANTÍAS, LIMITACIÓN DE RESPONSABILIDAD E INDEMNIZACIÓN</h2>
                <p>Usted acuerda que el uso de los servicios es a su propio riesgo. Los servicios, incluyendo el sitio, el contenido, el software, el material del usuario y cualquier otro material que contenga o se proporcione en el sitio, se proporcionan “en la condición que se encuentra” y, en la medida más amplia permitida por la ley, se proporcionan sin garantías de ninguna clase, ya sea expresas o implícitas. Sin limitar lo anterior, “{name}" no emite ninguna garantía de idoneidad para un fin específico, titularidad, comerciabilidad, integridad, disponibilidad, seguridad, compatibilidad o inexistencia de violaciones; o que los servicios serán ininterrumpidos o que no tendrán virus u otros componentes dañinos, o que serán precisos, sin errores o confiables. En ningún caso, “{name}”, sus afiliadas, subsidiarias, empleados, agentes y proveedores (incluyendo distribuidores y licenciantes de contenido), serán responsables de daños y perjuicios directos, indirectos, punitivos, incidentales, especiales, resultantes o de otra naturaleza, que resulten o en cualquier forma se relacionen con el uso de los servicios de “{name}”.</p>

                <h2>NOTIFICACIÓN Y PROCEDIMIENTO PARA PRESENTAR RECLAMACIONES DE VIOLACIONES DE DERECHO</h2>
                <p>Si usted considera que el Contenido, el Material del Usuario o el material de otro tipo que se proporciona a través del Servicio, incluso a través de un vínculo, viola sus derechos de autor, deberá notificar a “{name}” la reclamación de violación de acuerdo con el procedimiento que se prevé más adelante. Procesaremos todas las notificaciones de presuntas violaciones que “{name}” reciba y tomaremos las medidas apropiadas de acuerdo con las leyes de propiedad intelectual aplicables. La notificación de una reclamación de violación de derecho de autor deberá enviarse por correo electrónico al representante de derechos de autor de “{name}”,También puede contactarnos por correo {email}</p>
                <p>Para que sea válida, la notificación debe efectuarse por escrito e incluir la siguiente información: (a) firma electrónica o física de la persona autorizada a actuar por cuenta del propietario de un derecho de autor exclusivo; (b) descripción de la obra protegida por derechos de autor que usted reclama se ha violado; (c) una descripción de la ubicación en el Servicio del material que usted reclama que se está violando que sea razonablemente suficiente para permitir a “{name}” identificar y ubicar el material; (d) cómo puede “{name}” ponerse en contacto con usted, por ejemplo su domicilio, número telefónico y correo electrónico; (e) declaración por usted de que cree de buena fe que el uso en controversia no está autorizado por el propietario del derecho de autor, su representante o la ley; y (f) declaración por usted de que la información anterior en la notificación es exacta, que usted es el propietario del derecho de autor o el propietario de un derecho exclusivo sobre el material, o que está autorizado a actuar por cuenta de este. Los correos electrónicos enviados a: {email}, para fines distintos a comunicar violaciones de derechos de autor no serán respondidos.</p>
            
                <h2>JURISDICCIÓN</h2>
                <p>Estos Términos y Condiciones se regirán, interpretarán y estarán sujetos a las leyes de los Estados Unidos Mexicanos. Los Suscriptores se someten expresamente a las leyes y tribunales competentes de la Ciudad de Ensenada, Baja California renunciando a cualquier otra jurisdicción que por cualquier motivo les pudiese llegar a corresponder. Todos los conflictos, reclamaciones o controversias derivadas de o en relación con los presentes Términos y Condiciones, así como el incumplimiento, terminación, ejecución, interpretación o validez de las mismas, serán dirimidos exclusivamente por tribunales competentes de la Ciudad de Ensenada, Baja California.</p>

                <h2>DISPOSICIONES GENERALES</h2>
                <h3>Comunicaciones Electrónicas</h3>
                <p>Al utilizar el Servicio, usted aprueba recibir comunicaciones electrónicas de “{name}”, las cuales pueden incluir notificaciones, correos electrónicos de confirmación e información de operaciones de otro tipo, así como información concerniente o relativa a nuestro Servicio, y puede incluir boletines y comunicaciones promocionales de nosotros, sino han sido canceladas. Usted acepta que las notificaciones, acuerdos, divulgaciones u otras comunicaciones que le enviemos electrónicamente cumplirán con las disposiciones legales sobre comunicaciones, incluso que sean por escrito; asimismo, acuerda en actualizar su información personal inmediatamente de que haya un cambio en la dirección de su correo electrónico.</p>

                <h2>Cláusula para utilizar medios electrónicos de autenticación.</h2>
                <p>Las partes acuerdan que el Cliente podrá expresar su consentimiento respecto los presentes términos y condiciones, así como tener acceso a los servicios ofrecidos por “{name}” mediante medios electrónicos tales como NIP.</p>
           </main>
        </Layout>
    )
}

export default TerminosyCondiciones
